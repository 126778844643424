import React from "react"
import { graphql } from "gatsby"
import MyHelmet from "../components/MyHelmet"
import OffersPageTemplate from "../templates/OffersPageTemplate"

const OffersPage = ({ data }) => {
  const fm = data.directus
  const { products: actualItems } = fm
  const of = data.directus.offersPage
  return (
    <>
      <MyHelmet title={of.heading} description={of.subHeading} />
      <OffersPageTemplate
        heading={of.heading}
        subheading={of.subHeading}
        posts={actualItems}
      />
    </>
  )
}

export default OffersPage

export const OffersPageQuery = graphql`
  query OffersPage {
    directus {
      offersPage {
        heading
        subHeading
      }
      products(
        filter: {
          _and: [{ onOffer: { _eq: true } }, { hidden: { _eq: false } }]
        }
        sort: ["-date"]
      ) {
        id
        title
        date
        pinned
        slug
        subTitle
        featuredImage {
          id
        }
        body
      }
    }
  }
`
